@font-face {
  font-family: "Poppins"; /* Replace with your font name */
  src: url("./../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
@font-face {
  font-family: "Garet"; /* Replace with your font name */
  src: url("./../assets/fonts/Garet-Book.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
.filterContainer {
  width: 100%;
  display: flex;
}

.filterButton {
  background-color: transparent;
  font-family: "Poppins", sans-serif;
}

.order-btn {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px 10px;
  height: 56px;
  font-family: "Garet", sans-serif;
  color: #4d3f3f;
  font-weight: 700;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;
}
.order-btn p {
  margin-right: 20px;
}

.order-btn:hover {
  background: #4d3f3f;
  color: #fff;
  opacity: 0.9;
  transition: 0.5s;
}/*# sourceMappingURL=order.css.map */