@font-face {
  font-family: "Poppins"; /* Replace with your font name */
  src: url("./../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
@font-face {
  font-family: "Garet"; /* Replace with your font name */
  src: url("./../assets/fonts/Garet-Book.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
body {
  overflow-x: hidden;
}

.dashboardContainer {
  margin: auto;
  width: 97vw;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.btnText {
  padding-left: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.dashboardContent {
  margin: "auto";
  width: 75vw;
  padding: 0px;
  margin-left: 10px;
}

.barContainer {
  text-align: right;
}

.toolbarContainer {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.MuiDataGrid-columnHeaders .MuiCheckbox-root {
  border-radius: 0;
  color: white;
}

.MuiDataGrid-columnHeaders .MuiCheckbox-root.Mui-checked {
  color: white;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  font-size: 14px; /* Adjust the font size as needed */
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.MuiDataGrid-columnHeaders {
  background-color: #354e8e;
  color: white;
  font-size: 14px; /* Adjust the font size as needed */
  font-family: "Poppins", sans-serif;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
  border: none !important;
}

.dashboardContent__header p {
  font-size: 36px;
  font-family: "Garet", sans-serif;
  margin: 0;
}

@media (max-width: 600px) {
  .dashboardContainer {
    width: 85vw;
    padding: 5px 0;
    /* background-color: "red"; */
  }
  .dashboardContent {
    width: 85vw;
    margin: auto;
    /* padding: 0; */
    /* margin-left: 10px; */
  }
  .toolbarContainer {
    display: block;
    height: 80px;
  }
  .MuiDataGrid-root .MuiDataGrid-cell {
    font-size: 12px;
  }
  .MuiDataGrid-columnHeaders {
    font-size: 12px;
  }
}
.color-box {
  height: 100%;
  padding: 10px 1px;
  color: transparent;
} /*# sourceMappingURL=dashboard.css.map */
