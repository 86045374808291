@import "./../Variables/variables.scss";

.DrawContainer {
  width: 240px;
  // height: 90vh;
  border-radius: 18px;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  padding: 15px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.slidebtn {
  margin-bottom: 20px;
  width: 200px;
  font-size: 12px;
  padding: 10px 0px;
  text-decoration: none;
  border-radius: 3px;
  background-color: #fff;
  color: $buttonColor;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
}

.slidebtn.active {
  background: $buttonColor;
  color: #fff;
}

.btnText {
  padding-left: 10px;
  font-family: $textFont;
}

@media (max-width: 900px) {
  .DrawContainer {
    // background-color: red;
    height: auto;
    width: auto;
    border: none;
    border-radius: none;
  }

  .slidebtn {
  }

  userName {
    width: 100%; /* or a specific width based on your layout */
    word-wrap: break-word; /* Break long words if necessary */
  }

  .userName p,
  .userName span {
    white-space: normal; /* Allows the text to wrap normally */
    margin: 0; /* Adjust margins for better alignment if necessary */
  }

  .btnText {
    padding: 0;
    font-size: 12px;
  }
}
