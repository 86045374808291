@import "./../Variables/variables.scss";

.header {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.inputContainer {
  display: block;
}

.imageUpload {
  // width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid gray;
  overflow: hidden;

  margin: auto;
  display: flex;
  // justify-content: center;
  align-items: center;
  position: relative;

  .imageName {
    font-family: Poppins;
    padding: 5px;
    width: 85%;
    font-size: 14px;
  }
}

.formHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteIcon {
  color: #fff;
  // font-size: 40px;
}

.photoEditbtn {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  background: $blueColor;
}
