@font-face {
  font-family: "Poppins"; /* Replace with your font name */
  src: url("./../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
@font-face {
  font-family: "Garet"; /* Replace with your font name */
  src: url("./../assets/fonts/Garet-Book.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
.header {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.inputContainer {
  display: block;
}

.imageUpload {
  height: 50px;
  border-radius: 5px;
  border: 1px solid gray;
  overflow: hidden;
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
}
.imageUpload .imageName {
  font-family: Poppins;
  padding: 5px;
  width: 85%;
  font-size: 14px;
}

.formHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteIcon {
  color: #fff;
}

.photoEditbtn {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  background: #354e8e;
}/*# sourceMappingURL=addstock.css.map */