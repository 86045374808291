// /* Poppin */
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
// /* Garet */
// @import url("https://fonts.cdnfonts.com/css/garet");

@font-face {
  font-family: "Poppins"; /* Replace with your font name */
  src: url("./../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
@font-face {
  font-family: "Garet"; /* Replace with your font name */
  src: url("./../assets/fonts/Garet-Book.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}

//Color
$textheaderColor: #4d3f3f;
$buttonColor: #4d3f3f;
$inputFontColor: #4d3f3f;
$greenColor: #6ec531;
$redColor: #e81609;
$blueColor: #354e8e;
$CopperColor: #f2f3f7;

// Font
$textFont: "Poppins", sans-serif;
$inputFont: "Garet", sans-serif;
