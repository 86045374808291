@font-face {
  font-family: "Poppins"; /* Replace with your font name */
  src: url("./../../assets/fonts/Poppins-Regular.ttf") format("ttf");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
@font-face {
  font-family: "Garet"; /* Replace with your font name */
  src: url("./../../assets/fonts/Garet-Book.ttf") format("ttf");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}

.settingBtnContainer {
  width: 100%;
  display: inline-block;
}

.settingbtn {
  padding: 15px 15px;
  border-radius: 5px 5px 0 0;
  background-color: #fff;
  border: 1px solid #4d3f3f;
  font-size: 16px;
  color: #4d3f3f;
  /* margin-left: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.settingbtn:hover {
  transform: scale(0.95);
}

.settingbtn.active {
  background: #4d3f3f;
  color: #fff;
  border-bottom: none;
}

.settingText {
  font-family: "Poppins", sans-serif;
}

.adduser {
  background-color: #fff;
  display: flex;
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.adduser:hover {
  transform: scale(0.95);
}

.adduser span {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-right: 100px;
}

.adduserIcon {
  background: black;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
}

.page-header {
  font-size: 32px;
  color: #4d3f3f;
  margin-bottom: 10px;
  font-family: "Garet", sans-serif;
}

.shopLogo {
  width: 100%;
  height: 250px;
  border: 2px solid #4d3f3f;
  border-radius: 10px;
}
.shopLogo .title-logo {
  font-size: 16px;
  color: #4d3f3f;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.shopLogo .logo-name {
  background-color: #4d3f3f;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 20px;
}

.logo-img {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo-img img {
  -o-object-fit: cover;
  object-fit: cover;
}

.edit-btn {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: none;
  border-radius: 5px;
  padding: 5px 10px;
  height: 50px;
  font-family: "Garet", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-btn:hover {
  transform: scale(0.95);
  cursor: pointer;
}

.receipt-body {
  width: 95%;
  margin: 0 20px;
  box-shadow: none;
  border: none;
  font-size: 20px;
  font-family: "Garet", sans-serif;
  font-weight: bold;
}

.receipt-body:active,
.receipt-body:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.btn-container {
  margin-right: 25px;
  margin-top: 10px;
  display: flex;
  justify-content: end;
}
.btn-container button {
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin-left: 20px;
}
.btn-container .discard {
  background-color: white;
}
.btn-container .discard:hover,
.btn-container .save:hover,
.btn-container .discard:active,
.btn-container .save:active {
  transform: scale(0.95);
}
.btn-container .save {
  background-color: #4d3f3f;
  color: white;
}

@media (max-width: 600px) {
  .settingbtn {
    padding: 10px 10px;
    border-radius: 5px 5px 0 0;
    background-color: #4d3f3f;
    height: 50px;
    font-size: 12px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .adduser {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .adduser span {
    font-size: 12px;
    margin-right: 50px;
  }
  .page-header {
    font-size: 16px;
    color: #4d3f3f;
    margin-bottom: 10px;
    font-family: "Garet", sans-serif;
  }
  .edit-btn {
    font-size: 12px;
  }
  .shopLogo .title-logo {
    margin-left: 10px;
  }
  .receipt-body {
    width: 95%;
    margin: 0 10px;
    box-shadow: none;
    border: none;
    font-size: 12px;
    font-family: "Garet", sans-serif;
    font-weight: bold;
  }
} /*# sourceMappingURL=accsetting.css.map */
