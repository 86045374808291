@import "./../Variables/variables.scss";

.navTitle {
  text-align: center;
  font-weight: bold;
  font-family: $inputFont;
  font-size: 36px;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}

.btnText {
  padding-left: 10px;
  font-family: $textFont;
}

.userName p {
  color: #000;
  font-family: $textFont;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.userName span {
  color: $greenColor;
  text-align: center;
  font-family: $inputFont;
  font-size: 10px;
  font-style: normal;
  font-weight: 850;
  line-height: normal;
}

.avatars {
  border: 2px solid $greenColor;
}

.menu {
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 100%;
  left: -6%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

@media (max-width: 768px) {
  .navTitle {
    font-size: 16px;
  }
}
