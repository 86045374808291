@font-face {
  font-family: "Poppins"; /* Replace with your font name */
  src: url("./../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
@font-face {
  font-family: "Garet"; /* Replace with your font name */
  src: url("./../assets/fonts/Garet-Book.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
.navTitle {
  text-align: center;
  font-weight: bold;
  font-family: "Garet", sans-serif;
  font-size: 36px;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}

.btnText {
  padding-left: 10px;
  font-family: "Poppins", sans-serif;
}

.userName p {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.userName span {
  color: #6ec531;
  text-align: center;
  font-family: "Garet", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 850;
  line-height: normal;
}

.avatars {
  border: 2px solid #6ec531;
}

.menu {
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 100%;
  left: -6%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

@media (max-width: 768px) {
  .navTitle {
    font-size: 16px;
  }
}/*# sourceMappingURL=navbar.css.map */