@font-face {
  font-family: "Poppins"; /* Replace with your font name */
  src: url("./../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
@font-face {
  font-family: "Garet"; /* Replace with your font name */
  src: url("./../assets/fonts/Garet-Book.ttf") format("truetype");
  font-weight: normal; /* or bold, etc. */
  font-style: normal; /* or italic, etc. */
}
.loading {
  font-family: "Garet", sans-serif;
  color: #4d3f3f;
  font-size: 1.2rem;
  font-weight: 800;
} /*# sourceMappingURL=loading.css.map */
